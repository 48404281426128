<template>
  <PageLoading :messages="messages" />
</template>

<script>
import { mapActions } from "vuex";
import PageLoading from "@/components/PageLoading.vue";
import { MARKETPLACE_APP_URL } from "@/helpers/variables/scopes";

export default {
  name: "Callback",

  components: {
    PageLoading,
  },

  data: () => ({
    query: {},
    token: null,
    tries: 0,
    retryDelay: 8000,
    messages: ["", " "],
    retryMessages: [
      ["retry.justInstalled"],
      ["retry.ensureScopes", { link: MARKETPLACE_APP_URL }],
    ],
  }),

  computed: {
    retriesExceeded() {
      return this.tries + 1 >= (60 * 2000) / this.retryDelay;
    },
  },

  methods: {
    ...mapActions(["login"]),

    tryToLogin(auth) {
      this.login(auth)
        .then(() => {
          this.messages = ["", " "];
          this.$router.push({ name: "Auth" });
        })
        .catch((e) => {
          if (
            e.code !== "INVALID_GOOGLE_ACCESS_TOKEN" ||
            this.retriesExceeded
          ) {
            this.messages = ["", " "];
            this.$router.push({ name: "Login" });
          } else if (e.code === "INVALID_GOOGLE_ACCESS_TOKEN") {
            this.updateMessages();
            this.token = e.token;
            this.retryToLogin();
          }
        });
    },

    retryToLogin() {
      setTimeout(() => {
        this.tries++;
        this.tryToLogin({
          headers: {
            Authorization: this.token,
          },
          retry: this.tries > 0,
        });
      }, this.retryDelay);
    },

    updateMessages() {
      if (this.tries === 0) {
        this.messages = [this.$t("retry.waitingGoogle"), " "];
      }
      setTimeout(() => {
        const messages = [this.$t("retry.waitingGoogle")];
        if (this.tries === 0) {
          messages[1] = this.$t("retry.justInstalled");
        } else {
          messages[1] = this.$t(
            ...this.retryMessages[
              Math.round(Math.random()) * (this.retryMessages.length - 1)
            ]
          );
        }
        this.messages = messages;
      }, 2000);
    },
  },

  mounted() {
    const query = Object.assign({}, this.$route.query);
    this.$router.replace({ query: null });

    this.tryToLogin({ params: query });
  },
};
</script>
